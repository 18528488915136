import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "../store/reducers/userReducer";
import { translate } from "../utils";
import BreadcrumbNav from "./BreadcrumbNav";
// import './Donate.css';
import QrCode from '../images/qr.jpg'
import QrCodedonateimage from '../images/IMG_4719.JPG'
const Donate3 = () => {
    const userData = useSelector(selectUser);
    console.log("userData", userData);

    const ValueDataPrice = ['11 person 1001', '50 person 5001','100 person 10001'];
    const [activeValue, setActiveValue] = useState(ValueDataPrice[0]);
    const [inputValue, setInputValue] = useState(ValueDataPrice[0].split(' ')[2]);

    useEffect(() => {
        setActiveValue(ValueDataPrice[0]);
        setInputValue(ValueDataPrice[0].split(' ')[2]);
    }, []);

    const handleValueClick = (value) => {
        setActiveValue(value);
        setInputValue(value.split(' ')[2]);
    };
 
    const handleInputChange = (e) => {
        const value = e.target.value;
        setInputValue(value);
        setActiveValue('');
    };

    return (
        <>
            <BreadcrumbNav SecondElement={translate("Donate")} ThirdElement="0" />
            <section className="profile py-5 bg-white">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <nav>
                                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                    <a className="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true">Once</a>
                                    <a className="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="true">Yearly</a>
                                </div>
                            </nav>
                            <div className="tab-content" id="nav-tabContent">
                                <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                    <div className="value-buttons mt-3 row">
                                        {ValueDataPrice.map((item, index) => (
                                            <div 

                                                key={index}
                                                className={`col-lg-3 value-item ${item === activeValue ? 'active' : ''}`}
                                                onClick={() => handleValueClick(item)}
                                            >
                                                {item} Rs
                                                </div>

                                        ))}
                                    </div>
                                    <div className='row mt-2'>
                                        <div className='col-lg-1'>
                                            <h6>INR</h6>
                                        </div>
                                        <div className='col-lg-11'>
                                            <input
                                                type='number'
                                                className='form-control'
                                                value={inputValue}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>
                                    <br />
                                    <br />
                                </div>
                                <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                                    <div className="value-buttons mt-3 row">
                                    {ValueDataPrice.map((item, index) => (
                                            <div 

                                                key={index}
                                                className={`col-lg-3 value-item ${item === activeValue ? 'active' : ''}`}
                                                onClick={() => handleValueClick(item)}
                                            >
                                                {item} Rs
                                                </div>

                                        ))}
                                    </div>
                                    <div className='row mt-2'>
                                        <div className='col-lg-1'>
                                            <h6>INR</h6>
                                        </div>
                                        <div className='col-lg-11'>
                                            <input
                                                type='number'
                                                className='form-control'
                                                value={inputValue}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>
                                    <br />
                                    <br />
                                </div>
                            </div>
                            <div className="profile_content">
                                {/* <div className="profile_name">
                                    <label htmlFor="price">{translate("Donate Price")}</label>
                                    <input type="number" name="price" id="price" placeholder="रु" className="profile_input" />
                                </div> */}
                                <div className="profile_name">
                                    <label htmlFor="name">{translate("your-name")}</label>
                                    <input type="text" name="name" id="name" placeholder="Enter Your Name" className="profile_input" />
                                </div>
                                <div className="profile_name email">
                                    <label htmlFor="email">{translate("emailLbl")}</label>
                                    <input type="text" name="email" id="email" className="profile_input" placeholder="Enter Your Email" />
                                </div>
                                <div className="profile_name">
                                    <label htmlFor="mobile">{translate("Mobile")}</label>
                                    <input type="number" name="mobile" id="mobile" placeholder="Enter Your Mobile" className="profile_input" />
                                </div>
                                <div className="profile_name">
                                   
                                    <img src={QrCode}/>
                                </div>
                                <button type="button" className="profile_submit">
                                    {translate("Donate")}
                                </button>
                            </div>
                        </div>
                        <div className="col-lg-6">
                        <img className="donateimmage" src={QrCodedonateimage}/>

                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Donate3;
