import FeatureLayout from './FeatureLayout';
import React, { useState, useEffect } from 'react';
import LightGallery from 'lightgallery/react';
import lgVideo from 'lightgallery/plugins/video';
import axios from 'axios';

const HomePage = () => {
  const [imageData, setImageData] = useState([]);
  const [imageData1, setImageData1] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://admin.pravinbhaitogadia.com/Api/get_gallery', {
          headers: {
            'Content-Type': 'application/json',
          }
        });
        const allImages = response.data.data;

        const cowImages = allImages.filter(image => image.type === "gallery");
        setImageData(cowImages);
        // setImageData(response.data.data);
        setImageData1(cowImages.slice( 0,6));
      } catch (error) {
        console.error('Error fetching gallery data:', error);
      }
    };

    fetchData();
  }, []);

  
  return (
    <>
      <FeatureLayout />
      <div className='container'>
      <div id="rns-head-main" class="">
        <div className="left-sec">
          <h4 id="rns-main-logo" className="mb-0" style={{ color:"#FF8c19" }}>
            
            Dr Pravinbhai Togadia Gallery
            </h4>
         
              </div>
              <a href="/gallery-image" id="rns-Viewmore">
                View More</a>
                </div>
        <LightGallery plugins={[lgVideo]} mode="lg-fade">
          {imageData.map((image, index) => (
            <a
              key={index}
              // data-lg-size="200-200" // Set the size of the lightgallery
              className="gallery-item"
              data-src={image.image}
              // data-sub-html={`<h4>Photo by - <a href='${image.authorUrl}'>${image.author}</a></h4> <p> Location - <a href='${image.locationUrl}'>${image.location}</a></p>`}
            >


            </a>
          ))}

{imageData1.map((image) => (

<img

  className="img-responsive img-with-margin"
  src={image.image}
  width="200" // Set the fixed width of the image
  height="200"
// Set the fixed height of the image
/>
          ))}
        </LightGallery>
      </div>
    </>
  );
};

export default HomePage;



