// import React from 'react';
// import QrCodedonate from '../images/donateimage.jpeg';
// import { translate } from "../utils";
// import { Link } from 'react-router-dom';
//  // Import the CSS file

// const DonationForm = () => {
//   return (
//     <>
//       <div className='container'>
//         <div className='row mt-5 mb-5'>
//           <div className='col-lg-3'>
//             <div className="card donation-card">
//               <img src={QrCodedonate} className="card-img-top" alt="..." />
//               <div className="card-body">
//                 <h5 className="card-title">Food To The Poor</h5>
//                 <p className="card-text">आनंद और पुण्य बाँटने से बढ़ते है!</p>
//                 <a href="/donationimage" className="rns-viewmore">
//                   See all
//                 </a>
//                 <Link to='/donate-page'>
//                   <button type="button" className="profile_submit">
//                     {translate("Donate")}
//                   </button>
//                 </Link>
//               </div>
//             </div>
//           </div>
//           <div className='col-lg-3'>
//             <div className="card donation-card">
//               <img src={QrCodedonate} className="card-img-top" alt="..." />
//               <div className="card-body">
//                 <h5 className="card-title">BP-Suger Check</h5>
//                 <p className="card-text">आनंद और पुण्य बाँटने से बढ़ते है!</p>
//                 <a href="/donationimage1" className="rns-viewmore">
//                   See all
//                 </a>
//                 <Link to='/donate-page1'>
//                   <button type="button" className="profile_submit">
//                     {translate("Donate")}
//                   </button>
//                 </Link>
//               </div>
//             </div>
//           </div>
//           <div className='col-lg-3'>
//             <div className="card donation-card">
//               <img src={QrCodedonate} className="card-img-top" alt="..." />
//               <div className="card-body">
//                 <h5 className="card-title">Grass To Cow</h5>
//                 <p className="card-text">आनंद और पुण्य बाँटने से बढ़ते है!</p>
//                 <a href="/donationimage2" className="rns-viewmore">
//                   See all
//                 </a>
//                 <Link to='/donate-page2'>
//                   <button type="button" className="profile_submit">
//                     {translate("Donate")}
//                   </button>
//                 </Link>
//               </div>
//             </div>
//           </div>
//           <div className='col-lg-3'>
//             <div className="card donation-card">
//               <img src={QrCodedonate} className="card-img-top" alt="..." />
//               <div className="card-body">
//                 <h5 className="card-title">Hindu Help Line</h5>
//                 <p className="card-text">आनंद और पुण्य बाँटने से बढ़ते है!</p>
//                 <a href="/donationimage" className="rns-viewmore">
//                   See all
//                 </a>
//                 <Link to='/donate-page'>
//                   <button type="button" className="profile_submit">
//                     {translate("Donate")}
//                   </button>
//                 </Link>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };
// export default DonationForm;

import React, { useEffect, useState } from 'react';
import { translate } from "../utils";
import { Link } from 'react-router-dom';
import LightGallery from 'lightgallery/react';
import lgVideo from 'lightgallery/plugins/video';
import axios from 'axios';


const DonationForm = () => {
  const [imageDatanew, setImageDatanew] = useState([]);
  const [imageData1new, setImageData1new] = useState([]);
  const [imageData, setImageData] = useState([]);
  const [imageData1, setImageData1] = useState([]);
  const [imageData2, setImageData2] = useState([]);
  const [imageData3, setImageData3] = useState([]);
  const [imageData4, setImageData4] = useState([]);
  const [imageData5, setImageData5] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://admin.pravinbhaitogadia.com/Api/get_gallery', {
          headers: {
            'Content-Type': 'application/json',
          }
        });
        const allImages = response.data.data;
        const cowImages = allImages.filter(image => image.type === "food-to-poor");
        setImageDatanew(cowImages);
        // setImageData(response.data.data);
        setImageData1new(cowImages.slice(0, 1));
        const cowImages3 = allImages.filter(image => image.type === "sugar");
        setImageData(cowImages3);
        setImageData1(cowImages3.slice(0, 1));
        const cowImages2 = allImages.filter(image => image.type === "cow");
        setImageData2(cowImages2);
        setImageData3(cowImages2.slice(0, 1));
        const cowImages4 = allImages.filter(image => image.type === "hindu");
        setImageData4(cowImages4);
        setImageData5(cowImages4.slice(0, 1));

      } catch (error) {
        console.error('Error fetching gallery data:', error);
      }
    };
    fetchData();
  }, []);


  return (
    <>
      <div className='container'>

        <div className='row mt-5 mb-5'>
          <div className='col-lg-3'>
            <div className="card donation-card">
              <LightGallery plugins={[lgVideo]} mode="lg-fade">
                {imageDatanew.map((image, index) => (
                  <a
                    key={index}
                    // data-lg-size="200-200" // Set the size of the lightgallery
                    className="gallery-item"
                    data-src={image.image}
                  // data-sub-html={`<h4>Photo by - <a href='${image.authorUrl}'>${image.author}</a></h4> <p> Location - <a href='${image.locationUrl}'>${image.location}</a></p>`}
                  >


                  </a>
                ))}

                {imageData1new.map((image) => (

                  <img

                    className="img-responsive img-with-margin card-img-top dataimageshow"
                    src={image.image}
                    width="100%"
                    height="400"
                  // Set the fixed height of the image
                  />
                ))}
              </LightGallery>
              <span className="rns-viewmore">
                  See all
                </span>
              <div className="card-body">
                <h5 className="card-title">Food To The Poor</h5>
                <p className="card-text">आनंद और पुण्य बाँटने से बढ़ते है!</p>

                <Link to='/donate-page'>
                  <button type="button" className="profile_submit">
                    {translate("Donate")}
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className='col-lg-3'>
            <div className="card donation-card">
            <LightGallery plugins={[lgVideo]} mode="lg-fade">
                {imageData.map((image, index) => (
                  <a
                    key={index}
                    // data-lg-size="200-200" // Set the size of the lightgallery
                    className="gallery-item"
                    data-src={image.image}
                  // data-sub-html={`<h4>Photo by - <a href='${image.authorUrl}'>${image.author}</a></h4> <p> Location - <a href='${image.locationUrl}'>${image.location}</a></p>`}
                  >


                  </a>
                ))}

                {imageData1.map((image) => (

                  <img

                    className="img-responsive img-with-margin card-img-top dataimageshow"
                    src={image.image}
                    width="100%"
                    height="400"
                  // Set the fixed height of the image
                  />
                ))}
              </LightGallery>
              <span className="rns-viewmore">
                  See all
                </span>
              <div className="card-body">
                <h5 className="card-title">BP-Suger Check</h5>
                <p className="card-text">आनंद और पुण्य बाँटने से बढ़ते है!</p>

                <Link to='/donate-page1'>
                  <button type="button" className="profile_submit">
                    {translate("Donate")}
                  </button>
                </Link>
              </div>
            </div>
          </div>

          <div className='col-lg-3'>
            <div className="card donation-card">
            <LightGallery plugins={[lgVideo]} mode="lg-fade">
                {imageData2.map((image, index) => (
                  <a
                    key={index}
                    // data-lg-size="200-200" // Set the size of the lightgallery
                    className="gallery-item"
                    data-src={image.image}
                  // data-sub-html={`<h4>Photo by - <a href='${image.authorUrl}'>${image.author}</a></h4> <p> Location - <a href='${image.locationUrl}'>${image.location}</a></p>`}
                  >


                  </a>
                ))}

                {imageData3.map((image) => (

                  <img

                    className="img-responsive img-with-margin card-img-top dataimageshow"
                    src={image.image}
                    width="100%"
                    height="400"
                  // Set the fixed height of the image
                  />
                ))}
              </LightGallery>
              <span className="rns-viewmore">
                  See all
                </span>
              <div className="card-body">
                <h5 className="card-title">Grass To Cow</h5>
                <p className="card-text">आनंद और पुण्य बाँटने से बढ़ते है!</p>

                <Link to='/donate-page2'>
                  <button type="button" className="profile_submit">
                    {translate("Donate")}
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className='col-lg-3'>
            <div className="card donation-card">
            <LightGallery plugins={[lgVideo]} mode="lg-fade">
                {imageData4.map((image, index) => (
                  <a
                    key={index}
                    // data-lg-size="200-200" // Set the size of the lightgallery
                    className="gallery-item"
                    data-src={image.image}
                  // data-sub-html={`<h4>Photo by - <a href='${image.authorUrl}'>${image.author}</a></h4> <p> Location - <a href='${image.locationUrl}'>${image.location}</a></p>`}
                  >


                  </a>
                ))}

                {imageData5.map((image) => (

                  <img

                    className="img-responsive img-with-margin card-img-top dataimageshow"
                    src={image.image}
                    width="100%"
                    height="400"
                  // Set the fixed height of the image
                  />
                ))}

                 
              </LightGallery>
              <span className="rns-viewmore">
                  See all
                </span>
              <div className="card-body">
                <h5 className="card-title">Hindu Help Line</h5>
                <p className="card-text">आनंद और पुण्य बाँटने से बढ़ते है!</p>

                <Link to='/donate-page3'>
                  <button type="button" className="profile_submit">
                    {translate("Donate")}
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>

       
      </div>
    </>
  );
};
export default DonationForm;