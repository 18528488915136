import { initializeApp } from "firebase/app";
import {getAuth} from "firebase/auth";
import firebase from "firebase/compat/app"
import {getMessaging,onMessage} from "firebase/messaging";


const firebaseConfig = {
  apiKey: "AIzaSyBckTqMBdDM2ZX_ZPtgNPwLfbDn0-q6rEo",
  authDomain: "pravinbhaitogadia-409dc.firebaseapp.com",
  projectId: "pravinbhaitogadia-409dc",
  storageBucket: "pravinbhaitogadia-409dc.appspot.com",
  messagingSenderId: "110985915486",
  appId: "1:110985915486:web:924efaf497582c4550d8cf",
  measurementId: "G-MYGK4VKL07"
};


    // eslint-disable-next-line
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
  } else {
      firebase.app(); // if already initialized, use that one
  }

  const app = initializeApp(firebaseConfig);
  const messaging = getMessaging(app)
  // onMessage(messaging, (payload) => {
  //   console.log("payload", payload)

  // });

const authentication = getAuth();


export {app, authentication,messaging};

 