import React from 'react';
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton, LinkedinShareButton } from 'react-share';
import { FaFacebookSquare, FaTwitterSquare, FaWhatsapp, FaLinkedin } from 'react-icons/fa';

const ShareButton = () => {
    const url ='https://pravinbhaitogadia.com/'
  return (
    <div>
      <FacebookShareButton url={url}>
        <FaFacebookSquare size={20} />
      </FacebookShareButton>
      <TwitterShareButton url={url}>
        <FaTwitterSquare size={20} />
      </TwitterShareButton>
      <WhatsappShareButton url={url}>
        <FaWhatsapp size={20} />
      </WhatsappShareButton>
 
      <LinkedinShareButton url={url}>
        <FaLinkedin size={20} />
      </LinkedinShareButton>
    </div>
  );
}

export default ShareButton;
