
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "../store/reducers/userReducer";
import { translate } from "../utils";
import BreadcrumbNav from "./BreadcrumbNav";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";


const FilterPage = () => {
    const userData = useSelector(selectUser);
    console.log("userData", userData)
    const [brandDropdownVisible, setBrandDropdownVisible] = useState(false);
    const [cateDropdownVisible, setCateDropdownVisible] = useState(false);
    const handleParentClick = (dropdownName) => {
        switch (dropdownName) {
            case "sang":
                setBrandDropdownVisible(!brandDropdownVisible);
                break;
            case "pad":
                setCateDropdownVisible(!cateDropdownVisible);
            default:
                break;
        }
    };
    const handleCheckboxClick = (event) => {
        event.stopPropagation();
    };

    const Data = [
        {
            id: 1,
            name: "प्रवीण भाई तोगड़िया",
            position: "अंतरराष्ट्रीय हिंदू परिषद",
            city: "अध्यक्ष"

        },
        {
            id: 2,
            name: "pravinbhai",
            position: "head",
            city: "indore"

        },
        {
            id: 3,
            name: "pravinbhai",
            position: "head",
            city: "indore"

        }, 
        {
            id: 4,
            name: "pravinbhai",
            position: "head",
            city: "indore"

        }, 
        {
            id: 5,
            name: "pravinbhai",
            position: "head",
            city: "indore"

        }, 
        {
            id: 6,
            name: "pravinbhai",
            position: "head",
            city: "indore"

        },
         {
            id: 7,
            name: "pravinbhai",
            position: "head",
            city: "indore"

        },
        {
            id: 1,
            name: "pravinbhai",
            position: "head",
            city: "indore"

        },
        {
            id: 2,
            name: "pravinbhai",
            position: "head",
            city: "indore"

        },
        {
            id: 3,
            name: "pravinbhai",
            position: "head",
            city: "indore"

        },
         {
            id: 4,
            name: "pravinbhai",
            position: "head",
            city: "indore"

        }, 
        {
            id: 5,
            name: "pravinbhai",
            position: "head",
            city: "indore"

        }, 
        {
            id: 6,
            name: "pravinbhai",
            position: "head",
            city: "indore"

        },
         {
            id: 7,
            name: "pravinbhai",
            position: "head",
            city: "indore"

        }

    ]

    const [currentPage, setCurrentPage] = useState(0);
    const dataPerPage = 3; // Number of //
    const pagesVisited = currentPage * dataPerPage;
    const pageCount = Math.ceil(Data.length / dataPerPage);
    const currentData = Data.slice(pagesVisited, pagesVisited + dataPerPage);

    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected);
    };

    return (
        <>
            <BreadcrumbNav SecondElement={translate("Filter Data")} ThirdElement="0" />
            <section className="profile py-5 bg-white">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-lg-3">
                                    <div
                                        onClick={() => handleParentClick("sang")}
                                        className="main-chk"
                                    >
                                        संगठन
                                        <div className="i-con">
                                            <span>
                                                <i class="fa fa-angle-down" aria-hidden="true"></i>
                                            </span>
                                        </div>
                                        {brandDropdownVisible && (

                                            <>
                                                <div
                                                    className="form-check"
                                                    onClick={handleCheckboxClick}
                                                >
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                    >

                                                        अंतरराष्ट्रीय हिंदू परिषद
                                                    </label>

                                                </div>
                                                <div
                                                    className="form-check"
                                                    onClick={handleCheckboxClick}
                                                >
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"

                                                    />
                                                    <label
                                                        className="form-check-label"
                                                    >
                                                        राष्ट्रीय  हिंदू परिषद
                                                    </label>

                                                </div>
                                                <div
                                                    className="form-check"
                                                    onClick={handleCheckboxClick}
                                                >
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                    >
                                                        अंतरराष्ट्रीय बजरंग दल
                                                    </label>

                                                </div>
                                                <div
                                                    className="form-check"
                                                    onClick={handleCheckboxClick}
                                                >
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                    >

                                                        राष्ट्रीय बजरंग दल
                                                    </label>

                                                </div>
                                                <div
                                                    className="form-check"
                                                    onClick={handleCheckboxClick}
                                                >
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                    >
                                                        अंतर्राष्ट्रीय महिला परिषद
                                                    </label>

                                                </div>
                                                <div
                                                    className="form-check"
                                                    onClick={handleCheckboxClick}
                                                >
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                    >
                                                        राष्ट्रीय महिला परिषद
                                                    </label>

                                                </div>
                                                {/* <div
                                                    className="form-check"
                                                    onClick={handleCheckboxClick}
                                                >
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                    >

                                                        अंतरराष्ट्रीय हिंदू परिषद
                                                    </label>

                                                </div>
                                                <div
                                                    className="form-check"
                                                    onClick={handleCheckboxClick}
                                                >
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                    >
                                                        राष्ट्रीय  हिंदू परिषद
                                                    </label>

                                                </div>
                                                <div
                                                    className="form-check"
                                                    onClick={handleCheckboxClick}
                                                >
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                    >
                                                        राष्ट्रीय बजरंग दल
                                                    </label>

                                                </div> */}
                                            </>





                                        )}
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div
                                        onClick={() => handleParentClick("pad")}
                                        className="main-chk"
                                    >
                                        पदस्थ
                                        <div className="i-con">
                                            <span>
                                                <i class="fa fa-angle-down" aria-hidden="true"></i>
                                            </span>
                                        </div>
                                        {cateDropdownVisible && (
                                            <div>


                                                <div
                                                    className="form-check"
                                                    onClick={handleCheckboxClick}
                                                >
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                    >
                                                        अध्यक्ष
                                                    </label>
                                                </div>
                                                <div
                                                    className="form-check"
                                                    onClick={handleCheckboxClick}
                                                >
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                    >
                                                        उपाध्यक्ष
                                                    </label>
                                                </div>
                                                <div
                                                    className="form-check"
                                                    onClick={handleCheckboxClick}
                                                >
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                    >
                                                        महामंत्री
                                                    </label>
                                                </div>
                                                <div
                                                    className="form-check"
                                                    onClick={handleCheckboxClick}
                                                >
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                    >
                                                        मंत्री
                                                    </label>
                                                </div>
                                                <div
                                                    className="form-check"
                                                    onClick={handleCheckboxClick}
                                                >
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                    >
                                                        सदस्य
                                                    </label>
                                                </div>

                                            </div>
                                        )}
                                    </div>
                                </div>


                            </div>

                        </div>

                    </div>

                  

                    <table className="table mt-5">
                        <thead>
                            <tr>
                                <th scope="col">आइडी</th>
                                <th scope="col">नाम</th>
                                <th scope="col">संगठन</th>
                                <th scope="col">पदस्थ</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentData.map((item, index) => (
                                <tr key={index}>
                                    <th>{item.id}</th>
                                    <td>{item.name}</td>
                                    <td>{item.position}</td>
                                    <td>{item.city}</td>
                                    <td>
                                        <Link to='/iddetails-page'>

                                            <button className="btn btn-info">View Details</button>
                                        </Link>
                                    </td>

                                </tr>
                            ))}
                        </tbody>
                    </table>
                    
                    <ReactPaginate
                        previousLabel={"previous"}
                        nextLabel={"next"}
                        pageCount={pageCount}
                        onPageChange={handlePageChange}
                        containerClassName={"pagination"}
                        previousLinkClassName={"pagination__link"}
                        nextLinkClassName={"pagination__link"}
                        disabledClassName={"pagination__link--disabled"}
                        activeClassName={"pagination__link--active"}
                    />
                </div>
            </section>



        </>
    );
};

export default FilterPage;
