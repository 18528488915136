
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "../store/reducers/userReducer";
import { translate } from "../utils";
import BreadcrumbNav from "./BreadcrumbNav";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import axios from "axios";
const GalleryImage = () => {
    const userData = useSelector(selectUser);
    console.log("userData", userData)
    const [Data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const dataPerPage = 12; // Number of //
    const pagesVisited = currentPage * dataPerPage;
    const pageCount = Math.ceil(Data.length / dataPerPage);
    const currentData = Data.slice(pagesVisited, pagesVisited + dataPerPage);

    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected);
    };
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://admin.pravinbhaitogadia.com/Api/get_gallery', {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });
                const allImages = response.data.data;
        const cowImages = allImages.filter(image => image.type === "gallery");
                setData(cowImages);

            } catch (error) {
                console.error('Error fetching gallery data:', error);
            }
        };

        fetchData();
    }, []);

    return (

        <>
            <BreadcrumbNav SecondElement={translate("Gallery Image")} ThirdElement="0" />
            <section className="profile py-5 bg-white">
                <div className="container">
                    <div className="row">
                        {currentData.map((items) => (

                            <div className="col-lg-3">
                                <img
                                    className="img-responsive img-with-marginall"
                                    src={items.image}
                                    width="300" // Set the fixed width of the image
                                    height="200"

                                />
                           </div>
                        ))}



                    </div>




                    <ReactPaginate
                        previousLabel={"previous"}
                        nextLabel={"next"}
                        pageCount={pageCount}
                        onPageChange={handlePageChange}
                        containerClassName={"pagination"}
                        previousLinkClassName={"pagination__link"}
                        nextLinkClassName={"pagination__link"}
                        disabledClassName={"pagination__link--disabled"}
                        activeClassName={"pagination__link--active"}
                    />
                </div>
            </section>

        </>

    );
};
export default GalleryImage;