
import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "../store/reducers/userReducer";
import { translate } from "../utils";
import BreadcrumbNav from "./BreadcrumbNav";
import { useReactToPrint } from "react-to-print";

const FilterPageidshow = () => {
    const userData = useSelector(selectUser);
    console.log("userData", userData)
   

    const tableRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => tableRef.current,
      });
     

    return (
        <>
            <BreadcrumbNav SecondElement={translate("Id Details Data")} ThirdElement="0" />
            <section className="profile py-5 bg-white">
                <div className="container">
                  

                    <div className="row mt-5">
                        <div className="col-4"></div>
                        <div className="col-4" ref={tableRef}>

                            <div className="card carddata">
                                <div>
                                    <img className="idcardimg" src="https://admin.pravinbhaitogadia.com/public/images/1711750046.8227.jpeg" alt="" />
                                </div>
                                <div className="mt-3">

                                    <form>
                                        <div className="form-group row ">
                                            <label className="col-sm-6 text-center">आइडी </label>
                                            <div className="col-sm-6">
                                                1
                                            </div>
                                        </div>
                                        <div className="form-group row mt-3">
                                            <label className="col-sm-6 text-center">नाम</label>
                                            <div className="col-sm-6">
                                                प्रवीण भाई तोगड़िया
                                            </div>
                                        </div>
                                        <div className="form-group row mt-3">
                                            <label className="col-sm-6 text-center">संगठन</label>
                                            <div className="col-sm-6">
                                                अंतरराष्ट्रीय हिंदू परिषद
                                            </div>
                                        </div>
                                        <div className="form-group row mt-3">
                                            <label className="col-sm-6 text-center">पदस्थ</label>
                                            <div className="col-sm-6">
                                                अध्यक्ष
                                            </div>
                                        </div>
                                        <div className="form-group row mt-3">
                                            <label className="col-sm-6 text-center">Country</label>
                                            <div className="col-sm-6">
                                                India
                                            </div>
                                        </div>
                                        <div className="form-group row mt-3">
                                            <label className="col-sm-6 text-center">State</label>
                                            <div className="col-sm-6">
                                                M.P.
                                            </div>
                                        </div>
                                        <div className="form-group row mt-3">
                                            <label className="col-sm-6 text-center">City</label>
                                            <div className="col-sm-6">
                                                Indore
                                            </div>
                                        </div>
                                    </form>
                                    <div className="row">
                                        <div className="col-4"></div>
                                        <div className="col-4">
                                            <button className="btn btn-info mt-4 mb-4" onClick={handlePrint}>Id Details</button>

                                        </div>
                                        <div className="col-4"></div>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div className="col-4"></div>
                      
                    </div>
                </div>
            </section>



        </>
    );
};

export default FilterPageidshow;
