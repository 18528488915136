
// import React, { useState } from "react";
// import { useSelector } from "react-redux";
// import photoslogo from '../images/logopay.png'
// import { selectUser, updateProfileData, updateProfileImage } from "../store/reducers/userReducer";
// import { profileimgError, translate } from "../utils";
// import BreadcrumbNav from "./BreadcrumbNav";
// import { Button, Modal } from "react-bootstrap";

// const Donate = () => {
//     const userData = useSelector(selectUser);
//     console.log("userData", userData);

//     const[priceData,setPriceData]=useState('')
//     const [show, setShow] = useState(false);

//     const handleClose = () => setShow(false);
//     const handleShow = () => setShow(true);
//  const loadRazorpayScript = () => {
//     return new Promise((resolve, reject) => {
//       const script = document.createElement("script");
//       script.src = "https://checkout.razorpay.com/v1/checkout.js";
//       script.async = true;
//       script.onload = resolve;
//       script.onerror = reject;
//       document.body.appendChild(script);
//     });
//   };

//     const handlePayment = async () => {
//         try {
//             const amountInPaise = parseFloat(priceData) * 100;
//             const response = await loadRazorpayScript();
//             loadRazorpayScript()
//      .then((response) => {
//       console.log("response handlePayment: ", response);
//       // Code to execute after the script has loaded
//    })
//           const options = {
//             key: "rzp_test_yXpKwsLWjkzvBJ", // Replace with your actual key
//             amount: amountInPaise, 
//             currency: "INR",
//             name: "Pravin Bhai Togadia",
//             description: "Test Payment",
//             image:  photoslogo,
//             // order_id: response.id, // Order ID obtained from Razorpay
//             handler: (response) => {
//               console.log("response.razorpay_payment_id",response.razorpay_payment_id);
//               // Handle the success callback
//               window.location.href =
//                 // "https://pravinbhaitogadia.hirectjob.in/";
//                 "https://pravinbhaitogadia.com/";
//               console.log("Payment Successful:", response);
//             },
    
//             prefill: {
//               email: "test@example.com",
//               contact: "1234567890",
//             },
//             notes: {
//               address: "1234, Demo Address",
//             },
//             theme: {
//               color: "#FF8c19",
//             },
//           };
    
//           const rzp1 = new window.Razorpay(options);
//           rzp1.open();
//         } catch (error) {
//           console.error("Razorpay Load Error:", error);
//         }
//       };
     

//     return (
//         <>
//             <BreadcrumbNav SecondElement={translate("Donate")} ThirdElement="0" />
//             <section className="profile py-5 bg-white">
//                 <div className="container">
//                     <div className="row">
//                         <div className="col-md-6">
//                             <div className="profile_content">

                          

//                                 <div className="profile_name">
//                                     <label htmlFor="name">{translate("Donate Price")}</label>
//                                     <input type="number" value={priceData} onChange={(e)=>setPriceData(e.target.value)} name="price" id="price" placeholder="रु" className="profile_input" />
//                                 </div>
//                                 <div className="profile_name">
//                                     <label htmlFor="name">{translate("your-name")}</label>
//                                     <input type="text" name="name" id="name" placeholder="Enter Your Name" className="profile_input" />
//                                 </div>

//                                 <div className="profile_name email">
//                                     <label htmlFor="email">{translate("emailLbl")}</label>
//                                     <input type="text" name="email" id="email" className="profile_input" placeholder="Enter Your Email" />

//                                 </div>
//                                 <div className="profile_name">
//                                     <label htmlFor="name">{translate("Mobile")}</label>
//                                     <input type="number" name="mobile" id="mobile" placeholder="Enter Your Mobile" className="profile_input" />
//                                 </div>
//                                 <div className="payment-gateway-wrapper">
//                                     <input type="hidden" name="selected_payment_gateway" defaultValue="paypal" />
//                                     <ul>
//                                         <li data-gateway="paypal" classname="selected">
//                                             <div classname="img-select">
//                                                 <img src="https://fundorex.xgenious.com/assets/uploads/media-uploader/paypal1615321225.png" alt="" />
//                                             </div>
//                                         </li>
//                                         <li data-gateway="manual_payment">
//                                             <div classname="img-select">
//                                                 <img src="https://fundorex.xgenious.com/assets/uploads/media-uploader/bank-logo1645016001.png" alt="" />
//                                             </div>
//                                         </li>
//                                         <li data-gateway="mollie">
//                                             <div classname="img-select">
//                                                 <img src="https://fundorex.xgenious.com/assets/uploads/media-uploader/mollie1615321921.png" alt="" />
//                                             </div>
//                                         </li>
//                                         <li data-gateway="paytm">
//                                             <div classname="img-select">
//                                                 <img src="https://fundorex.xgenious.com/assets/uploads/media-uploader/paytm-logo-630x3361615321747.jpeg" alt="" />
//                                             </div>
//                                         </li>
//                                         <li data-gateway="stripe">
//                                             <div classname="img-select"><img src="https://fundorex.xgenious.com/assets/uploads/media-uploader/stripe1615321788.png" alt="" />
//                                             </div>
//                                         </li>
//                                         <li data-gateway="razorpay">
//                                             <div classname="img-select"><img src="https://fundorex.xgenious.com/assets/uploads/media-uploader/216-2164303-razorpay-logo-png1615321852.png" alt="" /></div>
//                                         </li>
//                                         <li data-gateway="flutterwave">
//                                             <div classname="img-select"><img src="https://fundorex.xgenious.com/assets/uploads/media-uploader/flutterwave-logo-vector1615321957.png" alt="" /></div>
//                                         </li>
//                                         <li data-gateway="paystack">
//                                             <div classname="img-select"><img src="https://fundorex.xgenious.com/assets/uploads/media-uploader/paystack1615321887.png" alt="" /></div>
//                                         </li>
//                                         <li data-gateway="midtrans">
//                                             <div classname="img-select"><img src="https://fundorex.xgenious.com/assets/uploads/media-uploader/mid1644059033.png" alt="" />
//                                             </div>
//                                         </li>
//                                         <li data-gateway="payfast">
//                                             <div classname="img-select"><img src="https://fundorex.xgenious.com/assets/uploads/media-uploader/payfast-press-release-image-011632313432.jpg" alt="" /></div>
//                                         </li>
//                                         <li data-gateway="cashfree">
//                                             <div classname="img-select"><img src="https://fundorex.xgenious.com/assets/uploads/media-uploader/cash-free1644059032.png" alt="" /></div>
//                                         </li>
//                                         <li data-gateway="instamojo">
//                                             <div classname="img-select"><img src="https://fundorex.xgenious.com/assets/uploads/media-uploader/instamogo1644059032.jpg" alt="" /></div>
//                                         </li>
//                                         <li data-gateway="marcadopago">
//                                             <div classname="img-select"><img src="https://fundorex.xgenious.com/assets/uploads/media-uploader/mercadopago-logo1644059033.png" alt="" /></div>
//                                         </li>
//                                         <li data-gateway="squareup">
//                                             <div classname="img-select"><img src="https://fundorex.xgenious.com/assets/uploads/media-uploader/sqaure1656825943.png" alt="" />
//                                             </div>
//                                         </li>
//                                         <li data-gateway="cinetpay">
//                                             <div classname="img-select"><img src="https://fundorex.xgenious.com/assets/uploads/media-uploader/cinetpay1656851559.jpg" alt="" /></div>
//                                         </li>
//                                         <li data-gateway="paytabs">
//                                             <div classname="img-select"><img src="https://fundorex.xgenious.com/assets/uploads/media-uploader/5-56308-paytabs-logo-hd-png-download1656855752.png" alt="" /></div>
//                                         </li>
//                                         <li data-gateway="billplz">
//                                             <div classname="img-select"><img src="https://fundorex.xgenious.com/assets/uploads/media-uploader/logo-facebook-dimension-912ae2521fe6b786a8c78a5cd1a7dfb31434c628a7d03f4377cbeb4707d6e305-21656931202.png" alt="" /></div>
//                                         </li>
//                                         <li data-gateway="zitopay">
//                                             <div classname="img-select"><img src="https://fundorex.xgenious.com/assets/uploads/media-uploader/zitopay-wallet1658992526.png" alt="" />
//                                             </div>
//                                         </li>
//                                     </ul>
//                                 </div>

//                                 <button type="button" className="profile_submit" onClick={handleShow}>
//                                     {translate("Donate")}
//                                 </button>
//                             </div>

//                         </div>
//                         <div className="col-md-6">
//                             <div className="profile_content">



//                                 <div className="prop__image">
//                                     <img src={userData.data && userData.data.profile} alt="Profile_Image" />


//                                 </div>

//                                 <div className="row">

//                                     <div className="col-sm-6  text-center">

//                                         <div className="profile_name">
//                                             <h5>{translate("Your Name")}</h5>
//                                         </div>
//                                     </div>
//                                     <div className="col-sm-6  text-center">

//                                         <div className="profile_name">

//                                             <h5> {userData.data && userData.data.name}</h5>
//                                         </div>
//                                     </div>
//                                     <div className="col-sm-6 text-center">

//                                         <div className="profile_name email">
//                                             <h5> Email</h5>

//                                         </div>
//                                     </div>
//                                     <div className="col-sm-6 text-center">

//                                         <div className="profile_name email">
//                                             <h5> {userData.data && userData.data.email}</h5>
//                                         </div>
//                                     </div>
//                                     <div className="col-sm-6 text-center">

//                                         <div className="profile_name mobile">
//                                             <h5>Your Mobile</h5>

//                                         </div>
//                                     </div>
//                                     <div className="col-sm-6 text-center">

//                                         <div className="profile_name mobile">
//                                             <h5> {userData.data && userData.data.mobile}</h5>
//                                         </div>
//                                     </div>
//                                     <div className="col-sm-6 text-center">
//                                         <div className="profile_name">
//                                             <h5>Join Date</h5>

//                                         </div>
//                                     </div>
//                                     <div className="col-sm-6 text-center">
//                                         <div className="profile_name">
//                                             <h5> {userData.data && userData.data.date}</h5>
//                                         </div>
//                                     </div>
//                                     <div className="col-sm-6 text-center">
//                                         <div className="profile_name">
//                                             <h5>Your Country</h5>

//                                         </div>
//                                     </div>
//                                     <div className="col-sm-6 text-center">
//                                         <div className="profile_name">
//                                             <h5> {userData.data && userData.data.country}</h5>
//                                         </div>
//                                     </div>
//                                     <div className="col-sm-6 text-center">
//                                         <div className="profile_name">
//                                             <h5>Your State</h5>

//                                         </div>
//                                     </div>
//                                     <div className="col-sm-6 text-center">
//                                         <div className="profile_name">
//                                             <h5> {userData.data && userData.data.state}</h5>
//                                         </div>
//                                     </div>
//                                     <div className="col-sm-6 text-center">
//                                         <div className="profile_name">
//                                             <h5>Your City</h5>

//                                         </div>
//                                     </div>
//                                     <div className="col-sm-6 text-center">
//                                         <div className="profile_name">
//                                             <h5> {userData.data && userData.data.city}</h5>
//                                         </div>
//                                     </div>


//                                 </div>


//                             </div>
//                         </div>
//                     </div>

//                 </div>
//             </section>



//             <Modal size="sl" aria-labelledby="contained-modal-title-vcenter" show={show} onHide={handleClose} animation={false} centered dialogClassName="border-radius-2">
//                 <div className="ModalWrapper">
//                     <div id="modal-content">
//                         <Modal.Header closeButton>
//                             <Modal.Title id="contained-modal-title-vcenter">{translate("Donate")}</Modal.Title>
//                         </Modal.Header>
//                         <Modal.Body>
//                             <div>
//                                 <div className="form-check">
//                                     <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" />
//                                     <label className="form-check-label" for="exampleRadios1">
//                                         Cash Payment
//                                     </label>
//                                 </div>
//                                 <div className="form-check">
//                                     <input onClick={handlePayment} className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="option2" />
//                                     <label className="form-check-label" for="exampleRadios2">
//                                         Online Payment
//                                     </label>
//                                 </div>
//                             </div>
//                         </Modal.Body>
//                         <Modal.Footer>
//                             <Button variant="secondary" onClick={handleClose}>
//                                 Close
//                             </Button>
//                             <Button variant="primary" onClick={handleClose}>
//                                 Save Changes
//                             </Button>
                          
//                         </Modal.Footer>
//                     </div>
//                 </div>
//             </Modal>
//         </>
//     );
// };

// export default Donate;


import React, { useState, useEffect } from "react";
// import { useSelector } from "react-redux";
// import { selectUser } from "../store/reducers/userReducer";
import { translate } from "../utils";
import BreadcrumbNav from "./BreadcrumbNav";
// import './Donate.css';
import QrCodedonateimage from '../images/IMG_3953.jpg'
import QrCode from '../images/qr.jpg'
const Donate = () => {
    // const userData = useSelector(selectUser);
    // console.log("userData", userData);

    const ValueDataPrice = ['10 people 250', '100 people 2500'];
    const [activeValue, setActiveValue] = useState(ValueDataPrice[0]);
    const [inputValue, setInputValue] = useState(ValueDataPrice[0].split(' ')[2]);

    useEffect(() => {
        setActiveValue(ValueDataPrice[0]);
        setInputValue(ValueDataPrice[0].split(' ')[2]);
    }, []);

    const handleValueClick = (value) => {
        setActiveValue(value);
        setInputValue(value.split(' ')[2]);
    };

    const handleInputChange = (e) => {
        const value = e.target.value;
        setInputValue(value);
        setActiveValue('');
    };

    
    return (
        <>
            <BreadcrumbNav SecondElement={translate("Donate")} ThirdElement="0" />
            <section className="profile py-5 bg-white">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <nav>
                                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                    <a className="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true">Once</a>
                                    <a className="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="true">Yearly</a>
                                </div>
                            </nav>
                            <div className="tab-content" id="nav-tabContent">
                                <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                    <div className="value-buttons mt-3 row">
                                        {ValueDataPrice.map((item, index) => (
                                            <div 

                                                key={index}
                                                className={`col-lg-4 value-item ${item === activeValue ? 'active' : ''}`}
                                                onClick={() => handleValueClick(item)}
                                            >
                                                {item} Rs
                                                </div>

                                        ))}
                                    </div>
                                    <div className='row mt-2'>
                                        <div className='col-lg-1'>
                                            <h6>INR</h6>
                                        </div>
                                        <div className='col-lg-11'>
                                            <input
                                                type='number'
                                                className='form-control'
                                                value={inputValue}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>
                                    <br />
                                    <br />
                                </div>
                                <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                                    <div className="value-buttons mt-3 row">
                                    {ValueDataPrice.map((item, index) => (
                                            <div 

                                                key={index}
                                                className={`col-lg-4 value-item ${item === activeValue ? 'active' : ''}`}
                                                onClick={() => handleValueClick(item)}
                                            >
                                                {item} Rs
                                                </div>

                                        ))}
                                    </div>
                                    <div className='row mt-2'>
                                        <div className='col-lg-1'>
                                            <h6>INR</h6>
                                        </div>
                                        <div className='col-lg-11'>
                                            <input
                                                type='number'
                                                className='form-control'
                                                value={inputValue}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>
                                    <br />
                                    <br />
                                </div>
                            </div>
                            <div className="profile_content">
                                {/* <div className="profile_name">
                                    <label htmlFor="price">{translate("Donate Price")}</label>
                                    <input type="number" name="price" id="price" placeholder="रु" className="profile_input" />
                                </div> */}
                                <div className="profile_name">
                                    <label htmlFor="name">{translate("your-name")}</label>
                                    <input type="text" name="name" id="name" placeholder="Enter Your Name" className="profile_input" />
                                </div>
                                <div className="profile_name email">
                                    <label htmlFor="email">{translate("emailLbl")}</label>
                                    <input type="text" name="email" id="email" className="profile_input" placeholder="Enter Your Email" />
                                </div>
                                <div className="profile_name">
                                    <label htmlFor="mobile">{translate("Mobile")}</label>
                                    <input type="number" name="mobile" id="mobile" placeholder="Enter Your Mobile" className="profile_input" />
                                </div>
                                <div className="profile_name">
                                   
                                    <img src={QrCode}/>
                                </div>
                                <button type="button" className="profile_submit">
                                    {translate("Donate")}
                                </button>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            {/* <div className="profile_content">
                                <div className="prop__image">
                                    <img src={userData.data && userData.data.profile} alt="Profile_Image" />
                                </div>
                                <div className="row">
                                    <div className="col-sm-6 text-center">
                                        <div className="profile_name">
                                            <h5>{translate("Your Name")}</h5>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 text-center">
                                        <div className="profile_name">
                                            <h5>{userData.data && userData.data.name}</h5>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 text-center">
                                        <div className="profile_name email">
                                            <h5>Email</h5>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 text-center">
                                        <div className="profile_name email">
                                            <h5>{userData.data && userData.data.email}</h5>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 text-center">
                                        <div className="profile_name mobile">
                                            <h5>Your Mobile</h5>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 text-center">
                                        <div className="profile_name mobile">
                                            <h5>{userData.data && userData.data.mobile}</h5>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 text-center">
                                        <div className="profile_name">
                                            <h5>Join Date</h5>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 text-center">
                                        <div className="profile_name">
                                            <h5>{userData.data && userData.data.date}</h5>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 text-center">
                                        <div className="profile_name">
                                            <h5>Your Country</h5>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 text-center">
                                        <div className="profile_name">
                                            <h5>{userData.data && userData.data.country}</h5>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 text-center">
                                        <div className="profile_name">
                                            <h5>Your State</h5>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 text-center">
                                        <div className="profile_name">
                                            <h5>{userData.data && userData.data.state}</h5>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 text-center">
                                        <div className="profile_name">
                                            <h5>Your City</h5>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 text-center">
                                        <div className="profile_name">
                                            <h5>{userData.data && userData.data.city}</h5>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <img className="donateimmage" src={QrCodedonateimage}/>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Donate;



